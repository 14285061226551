<template>
<div id="page-branch-list">
    <div class="vx-card p-6">
        <div class="vx-row">
            <div class="vx-col md:w-full flex items-center mb-2">
                <TitleScreen></TitleScreen>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-3">
                <label class="vs-input--label">{{$t('menuIntrusion.branch')}}</label>
                <span class="text-danger">*</span>
                <v-select class="" :disabled="isUpdate || userInfo.userRole != 'organization'" v-model="selectedOrganizationBranch"
                            :clearable="false" :options="organizationBranchOptions">
                    <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
            </div>

            <div class="vx-col md:w-1/3 mt-2">
                <label class="vs-input--label">Camera </label>
                <span class="text-danger mr-5">*</span>
                <vx-tooltip :title="$t('textCamera.instructionAi')"
                            color="warning"
                           >
                    <feather-icon color="primary" style="vertical-align: bottom; width: 14px;color: #1aaa55"
                                  type="border"
                                  icon-pack="feather"
                                  icon="HelpCircleIcon"
                    >
                    </feather-icon>
                </vx-tooltip>
                <v-select :disabled="isUpdate"
                            v-model="selectedCamera"
                            :clearable="false"
                            :options="cameraOptions">
                    <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
                <span class="text-danger text-sm">{{ errors.first('cameraName') }}</span>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col md:w-2/3 w-full">
                <label class="vs-input--label">{{ $t('menuFaceRecognition.eventName') }} </label>
                <span class="text-danger">*</span>
                <vs-input name="nameRestrictedarea" v-validate="'required'" class="w-full" v-model="heatmap.name"></vs-input>
                <span class="text-danger text-sm">{{ errors.first('nameRestrictedarea') }}</span>
            </div>
        </div>
        <div class="vx-row mt-5" v-if="isLoadWeekUse">
            <div class="vx-col w-full">
                <span class="color">{{ $t('menuIntrusion.utilization') }}:</span>
                <div class="mt-2">
                    <WeekComponent :selectTimes="weekUse"
                                    :nameEventBus="nameEventBusUse"></WeekComponent>
                </div>
            </div>
        </div>

        <!-- Save & Reset Button -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveChanges"
                               :disabled="!validateForm">  {{ `${isUpdate ? this.$t('button.update') : this.$t('button.create')}` }}
                    </vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="primary"
                               @click="$router.go(-1)">{{ $t('button.back') }}
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import vSelect from 'vue-select'
import WeekComponent from "../../../components/week-time/WeekComponentNew";
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    components: {
        vSelect,
        WeekComponent,
        TitleScreen
    },
    data(){
        return {
            isLoadWeekUse: false,
            nameEventBusUse: 'update-data-week-use',
            weekUse: {
                arr0: [],
                arr1: [],
                arr2: [],
                arr3: [],
                arr4: [],
                arr5: [],
                arr6: []
            },
            isUpdate: false,
            userInfo: {},
            heatmap: {
                organizationBranchId: null,
                cameraId: null,
                timeSolution: null,
            },
            //branch
            organizationBranchOptions: [],

            //camera
            cameraOptions: [],
            allCameraOptions:[],
            heatmapList: [],

            //time
            timeSpans: [
                {label: '1 tiếng', value: 1},
                {label: '2 tiếng', value: 2},
                {label: '3 tiếng', value: 3}
            ]
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        },
        selectedOrganizationBranch: {
            get(){
                let object = this.organizationBranchOptions.find(el => el.value == this.heatmap.organizationBranchId);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            async set(object) {
                this.heatmap.organizationBranchId = object ? object.value : null;
                if (object) {
                    await this.getListHeatmap()
                    this.isLoadWeekUse = true
                    if (!this.isUpdate) {
                        await this.getCamera();
                        this.selectedCamera = {value: this.cameraOptions[0] ? this.cameraOptions[0].value : null};
                    } else {
                        await this.findById()
                        await this.getCamera();
                        this.selectedCamera = this.cameraOptions.find(x => x.value == this.heatmap.cameraId);
                    }
                }
            }
        },
        selectedCamera: {
            get() {
                let object = this.cameraOptions.find(el => el.value == this.heatmap.cameraId);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(object) {
                this.heatmap.cameraId = object ? object.value : null;
            }
        },
        timeSolutionModel: {
            get() {
                let object = this.timeSpans.find(el => el.value == this.heatmap.timeSolution);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(object) {
                this.heatmap.timeSolution = object ? object.value : null
            }
        }
    },
    methods: {
        findById: function(){
            return new Promise((resolve, reject) => {
                const payload = {
                    id: this.$route.query.id
                }
                this.$crm.post('/heatmap/find-by-id', payload).then((res) => {
                    this.heatmap= res.data;
                    this.isUpdate = true;
                    this.isLoadWeekUse = true;
                    this.weekUse = JSON.parse(res.data.timeUse)
                    resolve();
                }).catch((err) => {
                    reject();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                })
            })
        },
        getListHeatmap: function(){
            return new Promise((resolve, reject) => {
                const payload = {
                    organizationBranchId: this.heatmap.organizationBranchId
                }
                this.$vs.loading();
                this.$crm.post('/heatmap/find-by-condition', payload).then((res) => {
                    this.heatmapList = res.data
                    resolve();
                    this.$vs.loading.close();
                }).catch((err) => {
                    reject();
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                })
            })
        },
        async saveChanges(){
            if (!this.validateForm) return;
            const result = await this.$validator.validateAll();
            if (!result) return;
            if (this.cameraOptions.length == 0) {
                return this.$vs.notify({
                    text: 'Chưa có dữ liệu camera',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            }
            let check = false;
            if (!this.heatmap.cameraId || !this.heatmap.organizationBranchId) {
                check = true;
                this.$vs.notify({
                    text: 'Vui lòng nhập đầy đủ thông tin.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            }
            if (check) return;
            let patch = this.isUpdate ?  '/heatmap/update' : '/heatmap/create';
            this.$vs.loading();
            this.heatmap.timeUse = JSON.stringify(this.weekUse);
            this.$crm.post(patch, this.heatmap).then(() => {
                this.$router.push('/user/heat-map-info').catch(() => {});
                this.$vs.loading.close();
                this.$vs.notify({
                    text: 'Tạo mới thành công sự kiện bản đồ nhiệt',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                })
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        updateTimeSpan(setting, hour, type) {
            setting[type] = hour.value;
            setting[`${type}_string`] = hour.label;
        },
        initScreen(){
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.userInfo = userInfo;
            if (userInfo.userRole != 'organization') this.heatmap.organizationBranchId = userInfo.organizationBranchId
            this.selectedOrganizationBranch = this.organizationBranchOptions[0];

            let heatmapId = this.$route.query.id;
            this.heatmap.id = parseInt(heatmapId);
            if (heatmapId) this.isUpdate = true
        },
        getCamera() {
            return new Promise((resolve, reject) => {
                this.cameraOptions = [];
                let url = `/camera/find-by-active-ai-function?functionName=ai-heatmap&organizationBranchId=${this.heatmap.organizationBranchId}`;
                this.$vs.loading();
                this.$crm.get(url).then((response) => {
                    if (response.data.length > 0) {
                        if (!this.isUpdate) {
                            let arrIdCamAlready = this.heatmapList.map(el => el.cameraId);
                            let filterCameAlready = response.data.filter(el => !arrIdCamAlready.includes(el.id));
                            this.cameraOptions = filterCameAlready.map(el => {
                                return {
                                    value: el.id,
                                    label: el.cameraName
                                }
                            })
                        } else {
                            this.cameraOptions = response.data.map(el => {
                                return {
                                    value: el.id,
                                    label: el.cameraName
                                }
                            })
                        }
                    }
                    resolve()
                    this.$vs.loading.close();
                }).catch((err) => {
                    reject()
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        getListOrganization: function(){
            return new Promise((resolve, reject) => {
                this.$crm.post('/organization-branch/all').then((res) => {
                    resolve();
                    this.organizationBranchOptions = res.data.map(el => {
                        return {
                            value: el.id,
                            label: el.organizationBranchName
                        }
                    });
                }).catch((err) =>{
                    reject();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                })
            })
        }
    },
    async created(){
        await this.getListOrganization();
        this.initScreen();
    },
    mounted() {
        this.$eventBus.$on(this.nameEventBusUse, (data) => {
            this.weekUse = data.data;
        });
    }
}
</script>

<style>

</style>
